
import Vue from 'vue';
import AdFormBanner from './AdFormBanner.vue';
import { AdFormOptions } from '@ads/types/AdForm';
import { isMobileOrTablet } from '@ads/utils/adx';

interface Props {
  settings: AdFormOptions;
}

interface Data {
  hasSiteHeader: boolean;
  isSticky: boolean;
  observer: MutationObserver | null;
  siteHeaderCheckDone: boolean;
}

interface Methods {
  handleSticky: () => void;
}

interface Computed {
  isMobileOrTablet: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    AdFormBanner,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      hasSiteHeader: false,
      isSticky: false,
      observer: null,
      siteHeaderCheckDone: false,
    };
  },
  computed: {
    isMobileOrTablet() {
      return isMobileOrTablet();
    },
  },
  watch: {
    siteHeaderCheckDone(value) {
      if (value && !this.hasSiteHeader) {
        const resizeObserver = new ResizeObserver(() => {
          const bodyPaddingTop = this.isMobileOrTablet ? 50 : 113;
          const body = document.querySelector('body') as HTMLElement;
          body.style.paddingTop = bodyPaddingTop + 'px';
        });
        resizeObserver.observe(document.querySelector('#plius-promotion-line') as Element);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      // Disable sticky in Internet Explorer
      if (this.$ua.browser() !== 'Internet Explorer') {
        this.handleSticky();
        window.addEventListener('scroll', this.handleSticky);
        window.addEventListener('resize', this.handleSticky);
      }

      setTimeout(() => {
        const siteHeader = document.querySelector('#delfi-adx-siteheader');
        this.hasSiteHeader = !!siteHeader?.querySelector('iframe');
        this.siteHeaderCheckDone = true;
      }, 1500);

      const callback = (mutations: any) => {
        this.$nextTick(() => {
          if (this.isMobileOrTablet) {
            const mutation = mutations[0] as MutationRecord;
            const nodes = mutation.removedNodes as NodeList;
            const node = nodes[0];
            if (node && node.nodeName === 'IFRAME') {
              document.querySelector('#plius-promotion-line')?.querySelector('iframe')?.parentElement?.setAttribute('style', '');
              document.querySelector('#plius-promotion-line')?.querySelector('iframe')?.remove();
            }
          }
        });
      };

      const promotionLine = this.$refs.promotionline as any;
      this.observer = new MutationObserver(callback);
      this.observer.observe(promotionLine.$el, { subtree: true, childList: true });
    });
  },
  methods: {
    handleSticky() {
      const menuTop: Element | null = document.querySelector('.header-primary .header .header-menu--top');
      const menuPrimary: Element | null = document.querySelector('.header-primary .header .header-menu--primary');
      const menuBottom: Element | null = document.querySelector('.header-bottom');

      const menuTopHeight: number = menuTop?.getBoundingClientRect().height || 0;
      const menuPrimaryHeight: number = menuPrimary?.getBoundingClientRect().height || 0;
      const menuBottomHeight: number = menuBottom?.clientHeight || 0;

      const promotionLineOffset = menuTopHeight + menuBottomHeight + menuPrimaryHeight;

      if (window) {
        const scroll: number = window.pageYOffset;
        this.isSticky = scroll >= menuTopHeight + menuBottomHeight;
      }

      // Set delay of header height definition caused by css transition
      setTimeout(() => {
        document.documentElement.style.setProperty('--delfi-promo-line-offset', `${promotionLineOffset}px`);
        document.documentElement.style.setProperty('--delfi-promo-line-stick-position', `${menuPrimaryHeight}px`);
      }, 305);
    },
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
});
