
import Vue from 'vue';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import srcConfig from '@core/config/src.config';

interface Props {
  content: Record<string, any>;
}

interface Data {
  locale: string;
  params: Record<string, any>;
  config: Record<string, any>;
  response: Record<string, any> | null;
}

export default Vue.extend<Data, unknown, unknown, Props>({
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      locale: 'lt',
      params: {},
      config: {},
      response: null,
    };
  },
  async mounted() {
    let idAppend = null;

    const hasId = /\?id=/gi.test(this.content.attrs.code);
    if (!hasId) {
      const split = this.content.attrs.code.split('-');
      const id = split[split.length - 1] || 1;
      idAppend = `?id=${id}`;
    }

    const params = { l: this.locale, id: `${this.content.attrs.code}${idAppend || ''}` };
    const response = await axios.get(srcConfig.ogEmbed + '/data.php', { adapter: jsonpAdapter, params, timeout: 10000 });
    this.response = response.data;
  },
});
