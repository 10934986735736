
import Vue from 'vue';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import getItemFromObject from 'lodash/get';
import { stripTags } from '@root/common/filters/htmlFormater';

interface Props {
  headline: HeadlineWithRelatedArticles;
  separator: boolean;
}

interface Computed {
  classModifier: Record<string, any>;
}

interface Methods {
  getItemFromObject: typeof getItemFromObject;
  getTitle: (headline: HeadlineWithRelatedArticles, strip: boolean) => string;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    LinkHandler,
  },
  props: {
    headline: {
      type: Object,
      required: true,
    },
    separator: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    classModifier() {
      return { 'headline-related-title--with-separator': this.separator };
    },
  },
  methods: {
    getItemFromObject,
    getTitle(headline, strip = false) {
      const title = headline.content.title?.html
        ? headline.content.title?.html.replace(/(<\/?h1>)/gi, '')
        : headline.content.title?.text
          ? headline.content.title?.text
          : '';

      return strip && typeof stripTags === 'function' ? stripTags(title) : title;
    },
  },
});
