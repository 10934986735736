import BaseService from '@root/common/base/BaseService';
import { DocumentNode, OperationVariables } from '@apollo/client/core';

import { DeleteCustomerData, GetCustomerData, CreateCustomerData, UpdateCustomerData } from '@root/modules/customer/types/customer';

import getCustomerDataQuery from '@root/modules/customer/graphql/getCustomerData.graphql';
import createCustomerDataQuery from '@root/modules/customer/graphql/createCustomerData.graphql';
import deleteCustomerDataQuery from '@root/modules/customer/graphql/deleteCustomerData.graphql';
import updateCustomerDataQuery from '@root/modules/customer/graphql/updateCustomerData.graphql';

type DeleteServiceVariables = DeleteCustomerData['variables'];
type GetServiceVariables = GetCustomerData['variables'];
type CreateServiceVariables = CreateCustomerData['variables'];
type UpdateServiceVariables = UpdateCustomerData['variables'];

interface MutationRequest {
  mutation: DocumentNode;
}

interface QueryRequest {
  query: DocumentNode;
}

interface RequestOptions<Request> {
  variables?: Request;
  context: {
    headers: {
      Authorization: string;
    };
  };
}

type MutationOptions<Request> = MutationRequest & RequestOptions<Request>;
type QueryOptions<Request> = QueryRequest & RequestOptions<Request>;
type RequestOption = MutationRequest | QueryRequest;

export default class CustomerDataService extends BaseService {
  private _customerToken: string | null = null;

  public setCustomerToken(customerToken: string) {
    this._customerToken = customerToken;
  }

  private getQueryOptions<Request>(variables: Request, requestOption: MutationRequest): MutationOptions<Request>;
  private getQueryOptions<Request>(variables: Request, requestOption: QueryRequest): QueryOptions<Request>;
  private getQueryOptions<Request>(variables: Request, requestOption: RequestOption) {
    const options: RequestOptions<Request> = {
      ...requestOption,
      context: {
        headers: {
          Authorization: `Bearer ${this._customerToken}`,
        },
      },
    };
    if (variables) {
      options.variables = variables;
    }
    return options;
  }

  private async query<Response, Request extends OperationVariables | undefined = undefined>({ variables, query }: { variables: Request; query: DocumentNode }) {
    if (!this._customerToken) {
      return;
    }

    const options = this.getQueryOptions(variables, { query });

    const apiProvider = this.createProvider('GraphQL');
    apiProvider.selectAPI('customer-data-api');

    const response = await apiProvider.query<Response>(options);
    return response.data;
  }

  private async mutation<Response, Request extends OperationVariables | undefined = undefined>({
    variables,
    mutation,
  }: {
    variables?: Request;
    mutation: DocumentNode;
  }) {
    if (!this._customerToken) {
      return;
    }

    const options = this.getQueryOptions(variables, { mutation });

    const apiProvider = this.createProvider('GraphQL');
    apiProvider.selectAPI('customer-data-api');

    const response = await apiProvider.mutation<Response>(options);
    return response.data || undefined;
  }

  public async delete(variables: DeleteServiceVariables) {
    const response = await this.mutation<DeleteCustomerData['data'], DeleteServiceVariables>({ variables, mutation: deleteCustomerDataQuery });

    return response;
  }

  public async create(variables: CreateServiceVariables) {
    const response = await this.mutation<CreateCustomerData['data'], CreateServiceVariables>({ variables, mutation: createCustomerDataQuery });

    return response;
  }

  public async get(variables: GetServiceVariables) {
    const response = await this.query<GetCustomerData['data'], GetServiceVariables>({ variables, query: getCustomerDataQuery });

    return response;
  }

  public async update(variables: UpdateServiceVariables) {
    const response = await this.mutation<CreateCustomerData['data'], UpdateServiceVariables>({ variables, mutation: updateCustomerDataQuery });

    return response;
  }
}
