import { routerComponents } from './routerComponents';
import combinedAliases from './routerAliases';

// Order: /static, /index, /:dynamic
// Match exact route before index: /login before /index/_slug
const routes = [
  {
    name: 'authors',
    path: '/authors',
    alias: combinedAliases.authors.map((alias) => `/${alias}`),
    component: routerComponents.Authors,
  },
  {
    name: 'latest',
    path: '/latest',
    alias: combinedAliases.latest.map((alias) => `/${alias}`),
    component: routerComponents.Latest,
  },
  {
    name: 'popular',
    path: '/popular',
    alias: combinedAliases.popular.map((alias) => `/${alias}`),
    component: routerComponents.Popular,
  },
  {
    name: 'search',
    path: '/search',
    alias: combinedAliases.search.map((alias) => `/${alias}`),
    component: routerComponents.Search,
  },
  {
    name: 'search-ru',
    path: '/ru/poisk',
    alias: combinedAliases.search.map((alias) => `/${alias}`),
    component: routerComponents.Search,
  },
  {
    name: 'search-en',
    path: '/en/search',
    alias: combinedAliases.search.map((alias) => `/${alias}`),
    component: routerComponents.Search,
  },
  {
    name: 'search-video',
    path: '/video/paieska',
    alias: combinedAliases.search.map((alias) => `/${alias}`),
    component: routerComponents.Search,
  },
  {
    name: 'author',
    path: '/author/:slug',
    alias: combinedAliases.author.map((alias) => `/${alias}/:slug`),
    component: routerComponents.Author,
  },
  {
    name: 'topics',
    path: '/topics',
    alias: combinedAliases.topics.map((alias) => `/${alias}`),
    component: routerComponents.Tags,
  },
  {
    name: 'topic',
    path: '/topics/:slug',
    alias: combinedAliases.topics.map((alias) => `/${alias}/:slug`),
    component: routerComponents.Tag,
  },
  {
    name: 'topics-en',
    path: '/en/topics',
    alias: combinedAliases.topics.map((alias) => `/${alias}`),
    component: routerComponents.Tags,
  },
  {
    name: 'topic-en',
    path: '/en/topics/:slug',
    alias: combinedAliases.topics.map((alias) => `/${alias}/:slug`),
    component: routerComponents.Tag,
  },
  {
    name: 'topics-ru',
    path: '/ru/temy',
    alias: combinedAliases.topics.map((alias) => `/${alias}`),
    component: routerComponents.Tags,
  },
  {
    name: 'topic-ru',
    path: '/ru/temy/:slug',
    alias: combinedAliases.topics.map((alias) => `/${alias}/:slug`),
    component: routerComponents.Tag,
  },
  {
    name: 'frontpage',
    path: '/',
    component: routerComponents.Frontpage,
  },
  {
    name: 'clientPage',
    path: '/klientas/:slug',
    component: routerComponents.Client,
  },
  {
    name: 'clientPage-en',
    path: '/en/client/:slug',
    component: routerComponents.Client,
  },
  {
    name: 'clientPage-ru',
    path: '/ru/klient/:slug',
    component: routerComponents.Client,
  },
  {
    name: 'customPage',
    path: '/:channel?/page/*',
    alias: combinedAliases.page.map((alias) => `/:channel?/${alias}/*`),
    component: routerComponents.Custom,
  },
  {
    name: 'bookmarks',
    path: '/saved',
    alias: combinedAliases.bookmarks.map((alias) => `/${alias}`),
    component: routerComponents.Bookmarks,
  },
  {
    name: 'bookmarks-en',
    path: '/en/saved',
    alias: combinedAliases.bookmarks.map((alias) => `/${alias}`),
    component: routerComponents.Bookmarks,
  },
  {
    name: 'bookmarks-ru',
    path: '/ru/sokhranenye',
    alias: combinedAliases.bookmarks.map((alias) => `/${alias}`),
    component: routerComponents.Bookmarks,
  },
  {
    name: 'channel',
    path: '/:channel',
    component: routerComponents.Frontpage, // FIXME: Use seperate Channel component?
  },
  {
    name: 'weather',
    path: '/:channel/forecast/:id+',
    alias: combinedAliases.forecast.map((alias) => `/:channel/${alias}/:id+`),
    component: routerComponents.Weather,
  },
  {
    name: 'articleShortUrl',
    path: '/a/:id/:comments?',
    component: routerComponents.Article,
  },
  {
    path: '/:channel/:category/:subcategory?/:slug-:id(\\d{5,})',
    component: routerComponents.ArticleBase,
    children: [
      {
        name: 'article',
        path: '',
        component: routerComponents.Article,
      },
      {
        name: 'articleComments',
        path: 'comments',
        alias: combinedAliases.comments.map((alias) => `${alias}`),
        component: routerComponents.ArticleComments,
      },
      {
        name: 'articleCommentsNapo',
        path: 'napo',
        component: routerComponents.ArticleCommentsNapo,
      },
    ],
  },
  {
    name: 'category',
    path: '/:channel/:parent?/:slug',
    component: routerComponents.Category,
  },
];

export default routes;
