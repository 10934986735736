
import Vue from 'vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import { gridConfig } from '@core/config/grid.config';
import { iconsConfig, iconList, careerIcons, careerTags, detectorCategories } from '@headlines/config/icons.config';
import { getDefinedLabels } from '@headlines/utils/getDefinedLabels';
import { Label } from '@root/modules/channel/types/channel';

import dayjs from 'dayjs';
import 'dayjs/locale/lt';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.locale('lt');
dayjs.extend(isBetween);

interface Props {
  headline: HeadlineWithRelatedArticles;
  size: string;
}

interface Data {
  iconList: Record<string, string>;
  contentType: {
    audio: boolean;
    awkwardQuestions: boolean;
    breakingNews: boolean;
    career: boolean;
    detector: boolean;
    detectorCategory: boolean;
    economist: boolean;
    everydayHeroes: boolean;
    exclusive: boolean;
    gallery: boolean;
    graph: boolean;
    important: boolean;
    interview: boolean;
    invest: boolean;
    liveBlog: boolean;
    premiere: boolean;
    projectSyndicate: boolean;
    reminds: boolean;
    renew: boolean;
    research: boolean;
    specialProject: boolean;
    stream?: boolean;
    streamSoon?: boolean;
    test: boolean;
    video: boolean;
    saugu: boolean;
    sauguComment: boolean;
    regionNews: boolean;
  };
}

interface Computed {
  isMobileView: boolean;
  isStream: boolean;
  isStreamDvr: boolean;
  isStreamSoon: boolean;
  absoluteIcons: any;
  flexIcons: any;
  definedLabels: Label[];
}

type VideoStreamDateRange = {
  start: string;
  end: string;
};

interface Methods {
  hasIcon: (icon: string) => boolean;
  hasTagId: (tagId: number) => boolean;
  isPrimaryCategory: (categoryId: number) => boolean;
  getClassModifier: (name: string) => string[];
  getLabelClassModifier: (label: Record<string, any>) => string[];
  getVideoStreamDateRange: () => VideoStreamDateRange | null;
  weightIcons: (icons: string[]) => string[];
}

interface Process {
  client: boolean;
}

declare let process: Process;

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    BaseIcon,
  },
  props: {
    headline: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  data() {
    return {
      iconList,
      contentType: {
        audio:
          this.headline.relatedEntities?.hasAudio || !!this.headline.settings?.find((setting) => setting.key === 'frontPageAudio' && setting.value === true),
        awkwardQuestions:
          !!this.headline.sources?.items?.find((source) => source.id === 96207701) ||
          (this.headline.settings?.find((setting) => setting.key === 'sources')?.value.length &&
            this.headline.settings?.find((setting) => setting.key === 'sources')?.value[0]['name'] === 'Nepatogūs klausimai'),
        breakingNews: !!this.headline.settings?.find((setting) => setting.key === 'frontPageBreakingNews' && setting.value === true),
        career:
          !!this.headline.tags?.items?.find((tag) => careerTags.includes(tag.id)) ||
          !!this.headline.settings?.find((setting) => careerIcons.includes(setting.key) && setting.value === true),
        detector: this.headline.primaryCategory?.parentCategory?.id === 85827763 || this.headline.primaryCategory?.parentCategory?.id === 79440681,
        detectorCategory: detectorCategories.includes(this.headline.primaryCategory?.id),
        economist:
          !!this.headline.tags?.items?.find((tag) => tag.id === 49711978) ||
          !!this.headline.sources?.items?.find((source) => source.id === 82072961) ||
          (this.headline.settings?.find((setting) => setting.key === 'sources')?.value.length &&
            this.headline.settings?.find((setting) => setting.key === 'sources')?.value[0]['name'] === 'The Economist'),
        everydayHeroes:
          !!this.headline.sources?.items?.find((source) => source.id === 96207705) ||
          (this.headline.settings?.find((setting) => setting.key === 'sources')?.value.length &&
            this.headline.settings?.find((setting) => setting.key === 'sources')?.value[0]['name'] === 'Kasdienybės herojai'),
        exclusive:
          (this.headline.settings?.find((setting) => setting.key === 'icon.exclusive')?.value ?? false) ||
          !!this.headline.settings?.find((setting) => setting.key === 'frontPageImportant' && setting.value === true),
        gallery:
          this.headline.relatedEntities?.hasGallery ||
          (this.headline.settings?.find((setting) => setting.key === 'icon.image')?.value ?? false) ||
          !!this.headline.settings?.find((setting) => setting.key === 'frontPageImage' && setting.value === true),
        graph:
          (this.headline.settings?.find((setting) => setting.key === 'icon.graph')?.value ?? false) ||
          !!this.headline.settings?.find((setting) => setting.key === 'frontPageGraph' && setting.value === true),
        important: this.headline.settings?.find((setting) => setting.key === 'icon.important')?.value ?? false,
        interview: !!this.headline.settings?.find((setting) => setting.key === 'frontPageInterview' && setting.value === true),
        invest:
          !!this.headline.settings?.find((setting) => setting.key === 'frontPageInvest' && setting.value === true) ||
          !!this.headline.sources?.items?.find((source) => source.id === 13162143) ||
          (this.headline.settings?.find((setting) => setting.key === 'sources')?.value.length &&
            this.headline.settings?.find((setting) => setting.key === 'sources')?.value[0]['name'] === 'Žurnalas „Investuok"'),
        liveBlog: !!this.headline.settings?.find((setting) => setting.key === 'articleLiveBlog' && setting.value === true),
        projectSyndicate:
          !!this.headline.sources?.items?.find((source) => source.id === 92312633) ||
          (this.headline.settings?.find((setting) => setting.key === 'sources')?.value.length &&
            this.headline.settings?.find((setting) => setting.key === 'sources')?.value[0]['name'] === 'Project Syndicate'),
        premiere:
          !!this.headline.settings?.find((setting) => setting.key === 'frontPagePremiere' && setting.value === true) ||
          !!(
            this.headline.content.paywall?.enabled &&
            this.headline.content.paywall.disableAt &&
            new Date() < new Date(this.headline.content.paywall.disableAt)
          ),
        reminds: !!this.headline.settings?.find((setting) => setting.key === 'frontPageDelfiReminds' && setting.value === true),
        renew:
          (this.headline.settings?.find((setting) => setting.key === 'icon.renewed')?.value ?? false) ||
          !!this.headline.settings?.find((setting) => setting.key === 'frontPageRenewed' && setting.value === true),
        research:
          (this.headline.settings?.find((setting) => setting.key === 'icon.research')?.value ?? false) ||
          !!this.headline.settings?.find((setting) => setting.key === 'frontPageResearch' && setting.value === true) ||
          !!this.headline.settings?.find((setting) => setting.key === 'frontPageInvestigativeJournalism' && setting.value === true),
        specialProject:
          (this.headline.settings?.find((setting) => setting.key === 'icon.spec_project')?.value ?? false) ||
          !!this.headline.settings?.find((setting) => setting.key === 'specialProject' && setting.value === true),
        test: !!this.headline.settings?.find((setting) => setting.key === 'frontPageTest' && setting.value === true),
        video:
          ((this.headline.settings?.find((setting) => setting.key === 'icon.video')?.value ?? false) &&
            this.headline.primaryCategory?.channel.url !== 'www.delfi.lt/video') ||
          (this.headline.relatedEntities?.hasVideo && this.headline.primaryCategory?.channel.url !== 'www.delfi.lt/video') ||
          !!this.headline.settings?.find((setting) => setting.key === 'frontPageVideo' && setting.value === true),
        saugu: !!this.headline.tags?.items?.find((tag) => tag.id === 96596245),
        sauguComment: !!this.headline.tags?.items?.find((tag) => tag.id === 96596571),
        regionNews: !!this.headline.tags?.items?.find((tag) => tag.id === 96604166),
      },
    };
  },
  computed: {
    isMobileView() {
      if (process.client) {
        const isIpad = (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) as boolean;

        return window.screen.width <= gridConfig.breakpoints.lg || isIpad;
      } else {
        return this.$ua && (this.$ua.isFromSmartphone() || this.$ua.isFromMobilephone() || this.$ua.isFromTablet());
      }
    },
    isStream() {
      const videoStreamDateRange = this.getVideoStreamDateRange();

      const streamEventStart =
        (videoStreamDateRange && videoStreamDateRange.start) ||
        (this.headline.settings?.find((setting: { key: string }) => setting.key === 'streamEventStart')?.value ?? false);
      const streamEventEnd =
        (videoStreamDateRange && videoStreamDateRange.end) ||
        (this.headline.settings?.find((setting: { key: string }) => setting.key === 'streamEventEnd')?.value ?? false);

      const hasLiveAttribute =
        this.headline.settings?.find((setting: { key: string }) => setting.key === 'icon.live' || setting.key === 'frontPageLiveVideo')?.value ?? false;

      if (!streamEventStart || !streamEventEnd) {
        return false;
      }

      return dayjs().isBetween(dayjs(streamEventStart), dayjs(streamEventEnd), 's') && hasLiveAttribute;
    },
    isStreamDvr() {
      const videoStreamDateRange = this.getVideoStreamDateRange();

      const streamEventStart =
        (videoStreamDateRange && videoStreamDateRange.start) ||
        (this.headline.settings?.find((setting: { key: string }) => setting.key === 'streamEventStart')?.value ?? false);
      const streamEventEnd =
        (videoStreamDateRange && videoStreamDateRange.end) ||
        (this.headline.settings?.find((setting: { key: string }) => setting.key === 'streamEventEnd')?.value ?? false);

      const hasLiveAttribute =
        this.headline.settings?.find((setting: { key: string }) => setting.key === 'icon.live' || setting.key === 'frontPageLiveVideo')?.value ?? false;

      if (!streamEventStart || !streamEventEnd) {
        return false;
      }

      return dayjs().isBetween(dayjs(streamEventStart), dayjs(streamEventEnd), 's') && !hasLiveAttribute;
    },
    isStreamSoon() {
      const videoStreamDateRange = this.getVideoStreamDateRange();

      const streamEventStart =
        (videoStreamDateRange && videoStreamDateRange.start) ||
        (this.headline.settings?.find((setting: { key: string }) => setting.key === 'streamEventStart')?.value ?? false);
      const streamEventEnd =
        (videoStreamDateRange && videoStreamDateRange.end) ||
        (this.headline.settings?.find((setting: { key: string }) => setting.key === 'streamEventEnd')?.value ?? false);

      const hasLiveAttribute =
        this.headline.settings?.find((setting: { key: string }) => setting.key === 'frontPageLiveVideo' || setting.key === 'icon.live')?.value ?? false;

      if (!streamEventStart || !streamEventEnd) {
        return false;
      }

      return dayjs().isBetween(dayjs(streamEventStart).subtract(5, 'minutes'), dayjs(streamEventStart), 's') && hasLiveAttribute;
    },
    absoluteIcons() {
      const icons: string[] = [];

      Object.entries(this.contentType).forEach(([name, isEnabled]) => {
        const icon = iconsConfig[name];

        if (icon && isEnabled && icon.position && icon.position === 'bottom') {
          icons.push(name);
        }
      });

      // Detect youtube streams
      if (this.isStream) {
        icons.push('stream');
      }

      // Detect dvideo streams
      if (this.isStreamDvr) {
        icons.push('streamDvr');
      }

      // Detect youtube streams 5min before
      if (this.isStreamSoon) {
        icons.push('streamSoon');
      }

      return this.weightIcons(icons);
    },
    flexIcons() {
      const icons: string[] = [];

      Object.entries(this.contentType).forEach(([name, isEnabled]) => {
        const icon = iconsConfig[name];

        if (icon && isEnabled && icon.position && (icon.position === 'right' || icon.position === 'left')) {
          icons.push(name);
        }
      });

      return icons;
    },
    definedLabels() {
      const definedLabels = getDefinedLabels(this.headline, 'image');
      return definedLabels;
    },
  },
  methods: {
    hasIcon(icon) {
      return !!this.headline.settings?.find((setting) => setting.key === icon && setting.value === true);
    },
    hasTagId(tagId) {
      return !!this.headline.tags?.items?.find((tag) => tag.id === tagId);
    },
    isPrimaryCategory(categoryId) {
      return this.headline.primaryCategory?.id === categoryId;
    },
    getClassModifier(name) {
      const modifiers: string[] = [];

      const icon = iconsConfig[name];
      if (icon) {
        if (icon.type && icon.type !== 'default') {
          modifiers.push(`headline-icons__icon--${icon.type}`);
        }

        if (icon.position && icon.position !== 'right') {
          modifiers.push(`headline-icons__icon--${icon.position}`);
        }

        if (icon.fa) {
          modifiers.push(`headline-icons__icon--fa`);
        }

        if (icon.color) {
          modifiers.push(`headline-icons__icon--${icon.color}`);
        }
      }

      modifiers.push(`headline-icons__icon--${name}`);

      return modifiers;
    },
    getLabelClassModifier(label) {
      const modifiers: string[] = [];

      if (label) {
        if (label.fa) {
          modifiers.push(`headline-icons__icon--fa`);
        }

        if (label.position) {
          modifiers.push(`headline-icons__icon--${label.position}`);
        }

        if (label.type) {
          modifiers.push(`headline-icons__icon--${label.type}`);
        } else {
          modifiers.push(`headline-icons__icon--default`);
        }

        if (label.color) {
          modifiers.push(`headline-icons__icon--${label.color}`);
        }
        if (label.class) {
          modifiers.push(`headline-icons__icon--${label.class}`);
        }

        if (label.text) {
          modifiers.push(`headline-icons__icon--text-thin`);
        }

        if (label.text && label.image) {
          modifiers.push(`headline-icons__icon--flex`);
        }
      }

      return modifiers;
    },
    getVideoStreamDateRange() {
      let videoStreamStart: string | boolean = false;
      let videoStreamEnd: string | boolean = false;

      const videoStreamDateRange = this.headline.settings?.find((setting: { key: string }) => setting.key === 'videoStreamDateRange')?.value ?? false;
      if (videoStreamDateRange && videoStreamDateRange.length === 2) {
        videoStreamStart = dayjs(videoStreamDateRange[0], 'DD.MM.YYYY HH:mm').toISOString();
        videoStreamEnd = dayjs(videoStreamDateRange[1], 'DD.MM.YYYY HH:mm').toISOString();

        return {
          start: videoStreamStart,
          end: videoStreamEnd,
        };
      }

      return null;
    },
    weightIcons(icons: string[]) {
      const weightedIcons = icons
        .map((icon) => {
          return { ...iconsConfig[icon], name: icon };
        })
        .sort((a, b) => a.weight - b.weight);

      return weightedIcons.length ? [weightedIcons[0].name] : [];
    },
  },
});
