const key = {
  ENV_DEFAULT_KEY: process.env.PORTAL_ROOT_ENVIRONMENT || 'default_env',
  DEPLOY_PATH: 'portal_deploy_path',
};

const apis = {
  apisEndpoints: {
    default_env: {
      ARTICLE_EXTRA_CONTENT_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/article_extra.php',
      ARTICLE_EXTRA_CONTENT_API_URL: 'https://www.delfi.lt/misc/pc/article_extra.php',
      CONFIG_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/config.php',
      CONFIG_API_URL: 'https://www.delfi.lt/misc/pc/config.php',
      FEED_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/feed.php',
      FEED_API_URL: 'https://www.delfi.lt/misc/pc/feed.php',
      COMMENT_FE_URL: 'https://g.delfi.lt/misc/comment-fe/loader.js',
      CONTENT_API_URL_V3: 'https://content.api.delfi.lt/content/v3',
      CONTENT_API_URL_V3_SERVER: 'https://content.api.delfi.lt/content/v3',
      MEDIA_API_URL_SERVER: 'https://images.delfi.lt/media-api-image-cropper/v1',
      MEDIA_API_URL: 'https://images.delfi.lt/media-api-image-cropper/v1',
      MEDIA_API_DATA_URL_SERVER: 'https://media.api.delfi.lt/media/v1',
      MEDIA_API_DATA_URL: 'https://media.api.delfi.lt/media/v1',
      CUSTOMER_DATA_API_URL: 'https://customer.api.delfi.lt/customer-data/v2',
      WEATHER_API_URL_SERVER: 'https://weather.api.delfi.lt/weather/v1/graphql',
      WEATHER_API_URL: 'https://weather.api.delfi.lt/weather/v1/graphql',
      CDN_PUBLIC_PATH: 'https://lt-production-portal-root.s3.delfi.lt',
    },
    lt_test: {
      ARTICLE_EXTRA_CONTENT_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/article_extra.php',
      ARTICLE_EXTRA_CONTENT_API_URL: 'https://www.delfi.lt/misc/pc/article_extra.php',
      CONFIG_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/config.php',
      CONFIG_API_URL: 'https://www.delfi.lt/misc/pc/config.php',
      FEED_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/feed.php',
      FEED_API_URL: 'https://www.delfi.lt/misc/pc/feed.php',
      COMMENT_FE_URL: 'https://comment-fe-lt-master.review.delfi.net/misc/comment-fe/loader.js',
      CONTENT_API_URL_V3: 'https://content.api.test.delfi.lt/content/v3',
      CONTENT_API_URL_V3_SERVER: 'https://content.api.test.delfi.lt/content/v3',
      MEDIA_API_URL_SERVER: 'https://api.delfi.lt/media-api-image-cropper/v1',
      MEDIA_API_URL: 'https://api.delfi.lt/media-api-image-cropper/v1',
      MEDIA_API_DATA_URL_SERVER: 'https://api.test.delfi.lt/media/v1',
      MEDIA_API_DATA_URL: 'https://api.test.delfi.lt/media/v1',
      CUSTOMER_DATA_API_URL: 'https://api.test.delfi.lt/customer-data/v2',
      WEATHER_API_URL_SERVER: 'https://weather.api.test.delfi.lt/weather/v1/graphql',
      WEATHER_API_URL: 'https://weather.api.test.delfi.lt/weather/v1/graphql',
      CDN_PUBLIC_PATH: process.env.CDN_PUBLIC_PATH || 'https://lt-test-portal-root.s3.test.delfi.lt',
    },
    lt_review: {
      ARTICLE_EXTRA_CONTENT_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/article_extra.php',
      ARTICLE_EXTRA_CONTENT_API_URL: 'https://www.delfi.lt/misc/pc/article_extra.php',
      CONFIG_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/config.php',
      CONFIG_API_URL: 'https://www.delfi.lt/misc/pc/config.php',
      FEED_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/feed.php',
      FEED_API_URL: 'https://www.delfi.lt/misc/pc/feed.php',
      COMMENT_FE_URL: 'https://g.delfi.lt/misc/comment-fe/loader.js',
      CONTENT_API_URL_V3: 'https://content.api.delfi.lt/content/v3',
      CONTENT_API_URL_V3_SERVER: 'https://content.api.delfi.lt/content/v3',
      MEDIA_API_URL_SERVER: 'https://api.delfi.lt/media-api-image-cropper/v1',
      MEDIA_API_URL: 'https://api.delfi.lt/media-api-image-cropper/v1',
      MEDIA_API_DATA_URL_SERVER: 'https://api.test.delfi.lt/media/v1',
      MEDIA_API_DATA_URL: 'https://media.api.test.delfi.lt/media/v1',
      CUSTOMER_DATA_API_URL: 'https://customer.api.delfi.lt/customer-data/v2',
      WEATHER_API_URL_SERVER: 'https://weather.api.delfi.lt/weather/v1/graphql',
      WEATHER_API_URL: 'https://weather.api.delfi.lt/weather/v1/graphql',
      CDN_PUBLIC_PATH: process.env.CDN_PUBLIC_PATH || `https://lt-review-portal-root.s3.review.delfi.lt/${key.DEPLOY_PATH}`,
    },
    lt_production: {
      ARTICLE_EXTRA_CONTENT_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/article_extra.php',
      ARTICLE_EXTRA_CONTENT_API_URL: 'https://www.delfi.lt/misc/pc/article_extra.php',
      CONFIG_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/config.php',
      CONFIG_API_URL: 'https://www.delfi.lt/misc/pc/config.php',
      FEED_API_URL_SERVER: 'https://www.delfi.lt/misc/pc/feed.php',
      FEED_API_URL: 'https://www.delfi.lt/misc/pc/feed.php',
      COMMENT_FE_URL: 'https://g.delfi.lt/misc/comment-fe/loader.js',
      CONTENT_API_URL_V3: 'https://content.api.delfi.lt/content/v3',
      CONTENT_API_URL_V3_SERVER: 'http://lt-production-content-api-v3-auto-deploy.content-api-v3/content/v3',
      MEDIA_API_URL_SERVER: 'https://images.delfi.lt/media-api-image-cropper/v1',
      MEDIA_API_URL: 'https://images.delfi.lt/media-api-image-cropper/v1',
      MEDIA_API_DATA_URL_SERVER: 'http://lt-production-media-api-auto-deploy.media-api/media/v1',
      MEDIA_API_DATA_URL: 'https://media.api.delfi.lt/media/v1',
      CUSTOMER_DATA_API_URL: 'https://customer.api.delfi.lt/customer-data/v2',
      WEATHER_API_URL_SERVER: 'http://lt-production-weather-api-auto-deploy.weather-api/weather/v1/graphql',
      WEATHER_API_URL: 'https://weather.api.delfi.lt/weather/v1/graphql',
      CDN_PUBLIC_PATH: process.env.CDN_PUBLIC_PATH || 'https://lt-production-portal-root.s3.delfi.lt',
    },
  },
};

const channelEnv = key.ENV_DEFAULT_KEY as keyof typeof apis.apisEndpoints;
const DEFAULT_CLIENT_CONFIG = apis.apisEndpoints[channelEnv];

export default DEFAULT_CLIENT_CONFIG;
