
import Vue from 'vue';
import Player from '@media/components/base/Player.vue';
import config from '@media/config/media.config';
import { AdsData, getPlayerAdSchedule } from '@media/utils/getPlayerAdSchedule';
import { getVideo } from '@media/services/getVideo';
import { VideoItem } from '@media/types/getVideo';
import { PortalPlayer } from '@media/services/player';
import { isDateBetween, isDateBefore } from '@core/filters/dateFormater';
import adSettings from '@media/config/videoPlayerSettings.config';
import { AdScheduleSettings } from '@media/types/PlayerAdSchedule';
import getRootConfig from '../../utils/getRootConfig';

interface $nuxtWindow extends Window {
  $nuxt: any;
}

interface Computed {
  hasChannelAccess: boolean;
  videoId: VideoItem['id'];
  isStream: boolean;
  isLive: boolean;
  hasLiveEnd: boolean;
  isLiveStartDateInFuture: boolean;
  isYoutubePlayer: boolean;
}

interface Props {
  data: {
    externalSource?: boolean;
    attrs: VideoItem;
    html?: string;
  };
  type: string;
  ads: AdsData;
  shouldPlayerFloat: boolean;
  lsa: boolean;
  aspectRatio: string;
}

interface Methods {
  setupPlayerWithData(): Promise<void>;
  initPlayer: () => Promise<void>;
  getPlayerAds: () => AdScheduleSettings | null;
  getVideoPoster: (attrs: VideoItem, videoData: VideoItem | null) => string;
  getSubtitleTracks: (videoData: VideoItem | null) => VideoSubtitleTrackData[] | null;
  getSettings: () => Record<string, unknown>;
  scrollEventCallback: () => void;
  initLegacyPlayer: () => void;
  appendYtPlayer: (videoCode: string) => void;
  appendD1Player: (videoCode: string) => void;
}

interface Data {
  legacyId: string;
  showOnlyPoster: boolean;
  videoPoster: string | null;
  autostart: boolean;
  containerId: string;
  videoData: VideoItem | null;
  player: PortalPlayer | null;
  className: string;
}

interface VideoSubtitleTrackData {
  default: boolean;
  file: string;
  label: string;
}

declare let window: $nuxtWindow;
let index = 0;

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Player,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    ads: {
      type: Object as () => AdsData,
      required: false,
      default: () => ({
        adFree: false,
        keywords: [],
        ppid: '',
      }),
    },
    shouldPlayerFloat: {
      type: Boolean,
      required: false,
      default: false,
    },
    lsa: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: String,
    },
  },
  data() {
    index++;

    return {
      legacyId: '',
      showOnlyPoster: false,
      videoPoster: null,
      containerId: `media-${this.type}-${this.data.attrs?.id}`,
      videoData: null,
      autostart: index === 1,
      player: null,
      className: `content-${this.data.attrs?.id}`,
    };
  },
  computed: {
    hasChannelAccess() {
      return this.$store.state.piano.access.channelAccess;
    },
    isYoutubePlayer() {
      return (
        (this.data.attrs?.type === 'youtube' && this.data.attrs?.code) ||
        (this.data.attrs?.streamData && this.data.attrs?.streamData?.videoVendorId && this.data.attrs?.streamData?.videoVendorId === 'youtube')
      );
    },
    videoId() {
      return this.data.attrs?.id || this.legacyId;
    },
    isLive() {
      const data = this.videoData?.metadata;
      const streamStartTime = data?.streamStartTime;
      const streamEndTime = data?.streamEndTime;

      const scheduleSet = Boolean(streamStartTime && streamEndTime);
      return scheduleSet ? isDateBetween(new Date().toString(), streamStartTime!, streamEndTime!) : true;
    },
    isStream() {
      return this.videoData?.type === 'STREAM';
    },
    hasLiveEnd() {
      const streamEndTime = this.videoData?.metadata.streamEndTime;

      if (!streamEndTime) {
        return false;
      }

      return isDateBefore(streamEndTime, new Date().toString());
    },
    isLiveStartDateInFuture() {
      const streamStartTime = this.videoData?.metadata.streamStartTime;

      if (!streamStartTime) {
        return false;
      }

      return isDateBefore(new Date().toString(), streamStartTime);
    },
  },
  watch: {
    async videoId() {
      if (!this.$isServer) {
        await this.setupPlayerWithData();
      }
    },
  },
  methods: {
    scrollEventCallback() {
      if (!this.shouldPlayerFloat) {
        return;
      }

      this.player?.decideFloatingModeToggle();
    },
    getPlayerAds() {
      if (this.lsa) {
        return null;
      }
      const { attrs } = this.data;

      const metadata = this.videoData?.metadata || attrs.metadata;
      const streamChannel = metadata?.streamChannel || '';

      const ads = Object.assign({ keywords: [] }, this.ads);
      ads.keywords.push(streamChannel);
      ads.ppid = this.$store.getters['adForm/getPPID'];

      return getPlayerAdSchedule(this.type, ads, this.hasChannelAccess, this.videoData?.videoData?.videoDuration);
    },
    getVideoPoster(attrs, videoData) {
      const videoPreviewImage = attrs?.metadata?.videoPreviewImage || videoData?.metadata.videoPreviewImage || {};
      if (videoPreviewImage && 'src' in videoPreviewImage && videoPreviewImage.src) {
        return videoPreviewImage.src;
      } else if (videoPreviewImage && 'id' in videoPreviewImage && videoPreviewImage?.id) {
        return `${config.api.mediaApiUrl}/${videoPreviewImage.id}.jpg?noup&r=16:9&w=960`;
      } else if (videoPreviewImage) {
        return `${config.api.mediaApiUrl}/${videoPreviewImage}.jpg?noup&r=16:9&w=960`;
      }
      return '';
    },
    getSubtitleTracks(videoData) {
      return (
        videoData?.subtitles?.map((track, index) => {
          return {
            default: index === 0,
            file: track.videoSubtitlesURL,
            label: track.language,
          };
        }) || []
      );
    },
    getSettings() {
      const { attrs } = this.data;
      this.videoPoster = this.getVideoPoster(attrs, this.videoData);
      const tracks = this.getSubtitleTracks(this.videoData) ?? undefined;
      const autostart = this.data.attrs.autostart ?? true;
      const advertising = this.videoData?.metadata.adsDisabled || attrs.metadata.adsDisabled ? undefined : (this.getPlayerAds() ?? undefined);
      const title = attrs?.metadata?.title || (this.videoData?.metadata.title ?? null) || '';
      const playerLogoDisabled = attrs.metadata?.playerLogoDisabled || this.videoData?.metadata.playerLogoDisabled || false;
      const videoFile =
        this.videoData?.type === 'VIDEO'
          ? (this.videoData?.videoData?.videoPlaylistLocation ?? null)
          : (this.videoData?.streamData?.videoPlaylistLocation ?? null);
      let playerLogo = undefined;

      if (videoFile) {
        this.$emit('load-player');
      }

      if (!playerLogoDisabled) {
        playerLogo = {
          file: config.player.logoUrl,
          hide: playerLogoDisabled,
        };
      }

      const settings = {
        ...adSettings,
        autostart: ((autostart && attrs.autostart) ?? true) ? 'viewable' : false,
        image: this.videoPoster,
        logo: playerLogo,
        file: videoFile,
        title,
        advertising,
        tracks,
        lsa: this.lsa,
      };

      if (this.aspectRatio) {
        settings.aspectratio = this.aspectRatio;
      }

      return settings;
    },
    async initPlayer() {
      if (!this.videoData) {
        return;
      }

      this.showOnlyPoster = false;
      await this.$nextTick();

      const settings = this.getSettings();

      if (!this.isLive) {
        this.showOnlyPoster = true;
        return;
      }

      // Remove previous instance from DOM if exists
      if (this.player && this.player.jwplayer) {
        this.player.jwplayer.remove();
      }

      try {
        this.player = new PortalPlayer(this.containerId, settings, this.videoData, { shouldPlayerFloat: this.shouldPlayerFloat });
      } catch (e) {
        this.showOnlyPoster = true;
        console.warn(e);
      }

      this.player?.on('player-ready', () => this.player!.setLayout(this.data.attrs.type));
      this.player?.on('setup-error', (e) => {
        this.showOnlyPoster = true;
        console.log('setup-error', e);
      });
      this.player?.on('error', (e) => {
        this.showOnlyPoster = true;
        console.log('setup-error', e);
      });
    },

    initLegacyPlayer() {
      if (this.legacyId.indexOf('youtube') !== -1) {
        const videoCode = this.legacyId.replace('youtube:', '');
        this.appendYtPlayer(videoCode);
      } else {
        this.appendD1Player(this.legacyId);
      }
    },

    async setupPlayerWithData() {
      const pattern_dvideo = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
      const pattern_eav = /embedded_article_video:\d+/gi;

      if (this.data.attrs && this.data.attrs?.id && !this.isYoutubePlayer) {
        this.videoData = this.data.attrs;

        if (!this.data.externalSource) {
          const data = await getVideo({ id: this.data.attrs?.id });
          this.videoData = (data && data.video.items[0]) || null;
        }

        const unwatch = this.$store.watch(
          (state) => state.piano.isScriptInited,
          async (isScriptInited: boolean) => {
            if (isScriptInited) {
              await this.initPlayer();
              await this.$nextTick();
              unwatch();
            } else {
              const { attrs } = this.data;
              this.videoPoster = this.getVideoPoster(attrs, this.videoData);
              this.showOnlyPoster = true;
            }
          },
          {
            immediate: true,
          }
        );
      } else if (this.isYoutubePlayer) {
        if (this.legacyId.indexOf('youtube') !== -1) {
          const videoId = this.legacyId.replace('youtube:', '');
          this.appendYtPlayer(videoId);
        } else {
          const youtubeIframe = this.data?.attrs?.code || this.data.attrs?.streamData?.videoPlaylistLocation || ('' as string);
          const regex = /youtube\.com\/embed\/([a-zA-Z0-9_-]+)/;
          const matches = youtubeIframe.match(regex);
          if (matches && matches.length > 0) {
            const videoId = matches[1];
            this.appendYtPlayer(videoId);
          }
        }
      } else if (this.data.html && this.data.html.match(pattern_dvideo)) {
        const matches = this.data.html.match(pattern_dvideo);
        if (matches && matches.length > 0) {
          const script = matches[0];
          const src = script.substring(script.indexOf('src=') + 5, script.indexOf('"', script.indexOf('src=') + 5));
          this.legacyId = src.substring(src.indexOf('#') + 1).replace('id=', '');
          this.initLegacyPlayer();
        }
      } else if (this.data.html && this.data.html.match(pattern_eav)) {
        const matches_eav = this.data.html.match(pattern_eav);
        if (matches_eav && matches_eav.length > 0) {
          const videoCode = matches_eav[0];
          const response = await fetch(`//www.delfi.lt/misc/pc/get_video_embed_code.php?videoCode=${videoCode}`);
          if (response.ok) {
            const data = await response.json();
            if (data.videoCode) {
              this.legacyId = data.videoCode;
              this.initLegacyPlayer();
            }
          }
        }
      }
    },
    appendYtPlayer(videoCode) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://g.delfi.lt/glt/c/delfi-live/yt.js';
      script.dataset.video = videoCode;
      script.dataset.width = '100%';
      script.dataset.autoplay = '1';
      if (this.lsa) {
        script.dataset.mute = '1';
      }

      const containerClassName = `youtube-${this.className}-container`;
      const playerContainer = window.document.getElementsByClassName(containerClassName)[0];
      if (playerContainer) {
        playerContainer.append(script);
      }
    },
    appendD1Player(videoCode) {
      const script = document.createElement('script');
      script.src = 'https://g.delfi.lt/components/video/player.js';
      script.dataset.provider = 'dvideo';
      script.dataset.id = videoCode;
      script.dataset.autoplay = '1';

      if (this.ads.adFree) {
        script.dataset.noads = '1';
      }

      if (typeof window !== 'undefined' && window.$nuxt && window.$nuxt.$route.params.category) {
        const categorySlug = window.$nuxt.$route.params.subcategory || window.$nuxt.$route.params.category;

        const customAdFormSettings = getRootConfig('page')?.article?.component?.adForm;
        if (customAdFormSettings) {
          if (Array.isArray(customAdFormSettings)) {
            const categoryAdsConfig = customAdFormSettings.find((item: { categories: string | string[] }) => item.categories.includes(categorySlug)) || [];
            if (categoryAdsConfig.length > 0 && categoryAdsConfig?.disableAds.includes('videoAds')) {
              script.dataset.noads = 'true';
            }
          }
        }
      }

      const playerContainer = window.document.getElementsByClassName(`${script.dataset.provider}-${this.className}-container`)[0];
      if (playerContainer) {
        playerContainer.append(script);
      }
    },
  },
  async mounted() {
    await this.setupPlayerWithData();

    if (this.shouldPlayerFloat) {
      document.addEventListener('scroll', this.scrollEventCallback);
    }
  },
  beforeDestroy() {
    this.player?.jwplayer.remove();
    index = 0;

    if (this.shouldPlayerFloat) {
      document.removeEventListener('scroll', this.scrollEventCallback);
    }
  },
});
