import { Component } from 'vue';
import AdminBlockTypeAttribute from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

export enum AdminBlockAttributeInputType {
  CHECKBOX = 'CHECKBOX',
  IMG_URL = 'IMG_URL',
  COLOR_PICKER = 'COLOR_PICKER',
  NUMBER_INPUT = 'NUMBER_INPUT',
  STRING_INPUT = 'STRING_INPUT',
  CODE_INPUT = 'CODE_INPUT',
  SELECT = 'SELECT',
  HIDDEN = 'HIDDEN',
}

export interface AdminBlockType {
  component: Component;
  attributes: AdminBlockTypeAttribute[];
  articlesCount: number;
}

export interface AdminBlockTypeAttribute {
  label: string;
  key: string;
  inputType: AdminBlockAttributeInputType;
  defaultValue?: unknown;
  typeOptions?: unknown;
}
