
import Vue from 'vue';
import { ImageItem } from '@gallery/types/Gallery';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import Tooltip from '@core/components/UI/Tooltip.vue';
import { TooltipPayload } from '@core/types/Tooltip';

interface Data {
  copyTooltip: TooltipPayload | null;
}

interface Props {
  caption: ImageItem['metadata']['title'];
  credit: ImageItem['metadata']['credit'];
  authors: string;
}

interface Computed {
  description: string;
}

export default Vue.extend<Data, unknown, Computed, Props>({
  components: {
    BaseIcon,
    Tooltip,
  },
  data() {
    return {
      copyTooltip: null,
    };
  },
  props: {
    caption: {
      type: String,
      required: false,
      default: '',
    },
    authors: {
      type: String,
      required: false,
      default: '',
    },
    credit: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    description() {
      return this.credit;
    },
  },
  methods: {
    facebookShare() {
      const link = window.location.href;
      const title = this.caption || (document && document.title);
      const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}&t=${encodeURIComponent(title)}`;

      const params = `width=${500},height=${400}`;
      window.open(url, 'share_dialog', params);
    },
    linkedInShare() {
      const link = window.location.href;
      const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(link)}`;

      const params = `width=${500},height=${400}`;
      window.open(url, 'share_dialog', params);
    },
    copyLink() {
      let textarea;
      let result = false;
      const link = window.location.href;

      try {
        navigator.clipboard.writeText(link);
        result = true;
      } catch {
        result = false;
      }

      if (!result) {
        try {
          textarea = document.createElement('textarea');
          textarea.setAttribute('readonly', 'true');
          textarea.setAttribute('contenteditable', 'true');
          textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
          textarea.value = link;

          document.body.appendChild(textarea);

          textarea.select();

          const range = document.createRange();
          range.selectNodeContents(textarea);

          const sel = window.getSelection();
          sel?.removeAllRanges();
          sel?.addRange(range);

          textarea.setSelectionRange(0, textarea.value.length);
          result = document.execCommand('copy');
        } catch (err) {
          result = false;
        } finally {
          if (textarea) {
            document.body.removeChild(textarea);
          }
        }
      }

      // manual copy fallback using prompt
      if (!result) {
        const isMac = navigator.platform.toUpperCase().includes('MAC');
        const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
        prompt(`Press ${copyHotkey}`, link);
      }

      if (result) {
        this.copyTooltip = {
          message: this.$t('portal_core.link_copied') as string,
          timestamp: String(new Date().getTime()),
        };
      }
    },
  },
});
