import { render, staticRenderFns } from "./BlockType20.vue?vue&type=template&id=253d8902&scoped=true"
import script from "./BlockType20.vue?vue&type=script&lang=ts"
export * from "./BlockType20.vue?vue&type=script&lang=ts"
import style0 from "./BlockType20.vue?vue&type=style&index=0&id=253d8902&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "253d8902",
  null
  
)

export default component.exports