import { Plugin } from '@nuxt/types';

/*
  https://docs.piano.io/getting-started-module-2-setting-up-piano-scripts/#thepianoscript
  https://docs.piano.io/track/id-implementation
  https://docs.piano.io/callbacks/
  */
const piano: Plugin = async (ctx) => {
  const { app, store, route } = ctx;
  const { locale = 'lt_LT', domain } = app.$channelConfig('settings');
  const { piano } = app.$channelConfig('features');

  const disableMaintenance = Number(route.query?.disableMaintenance) === 1 || false;
  const maintenanceMode = piano.maintenanceMode || false;

  if (!app.mixins) {
    app.mixins = [];
  }
  /*
      Piano SDK is initialized in the client-side plugin (when hydration starts)
      On checkout, Piano applies get parameters to the URL and reloads the page
      After reloading Piano SDK is initialized again, and it removes these parameters
      But because it happens **during** hydration, they are applied again by Vue-Router or Nuxt at the end of hydration
      So we need to remove them manually after hydration ends and after Piano SDK is initialized
   */
  app.mixins.push({
    async mounted() {
      await store.dispatch('piano/init', {
        aid: piano.account.aid,
        scriptSrc: piano.account.scriptSrc,
        locale,
        accessResources: piano.accessResources,
        offersConfig: piano.offers,
        ESPId: piano.account.ESPId,
        maintenanceMode: maintenanceMode && !disableMaintenance,
        defaultPromotions: piano.defaultPromotions,
        isApplication: Boolean(app.$channelConfig('settings').application.type),
        useAndroidAppApi: piano.useAndroidAppApi,
        useIosAppApi: piano.useIosAppApi,
      });
    },
  });

  /*
   * Whenever new content is loaded without a browser refresh, you’ll need to execute the
   * Piano experience you've designed in Composer so that each new applicable change on the
   * page or URL is made known to Composer in place of a true page load.
   * */

  app.router!.afterEach((to, from) => {
    if (from.name) {
      const routeName = to.name;

      store.dispatch('piano/setTags', [domain, routeName]);
      store.dispatch('piano/executeExperience');
    }
  });

  store.watch(
    (state) => state.piano.scriptLoaded,
    async (scriptLoaded) => {
      if (scriptLoaded) {
        /*
         * Should be fired only once, do some actions that are needed on first load
         * */

        const routeName = app.router?.currentRoute.name;
        await store.dispatch('piano/setTags', [domain, routeName]);

        app.$eventHandler.emit('piano-script-initialized');
      }
    },
    {
      immediate: true,
    }
  );

  store.watch(
    (state) => state.piano.access,
    async (access) => {
      app.$eventHandler.emit('piano-paywall-state', access);
    }
  );

  // Listen for Piano postMessages
  window.addEventListener('message', ({ data }: { data: string }) => {
    let parsedParams: any = undefined;

    try {
      parsedParams = JSON.parse(data);
    } catch {
      return;
    }

    // Dispatched when checkout completed modal is opened
    if (parsedParams?.event === 'checkoutStateChange' && parsedParams.params?.stateName === 'receipt') {
      store.commit('analytics/setClickEvent', {
        cXense: {
          eventName: 'PAYWALL_MODAL',
          eventData: { type: 'view' },
        },
        googleTagManager: {
          eventName: 'NEW_SUBSCRIPTION',
          eventData: { eventType: 'click', data: { order_customer_id: store.state.piano.profile.uid } },
        },
      });
    }
  });
};

export default piano;
